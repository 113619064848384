import React from "react";

// import { Container } from './styles';

function informations() {
  return (
    <>
      <section
        class="text-center space--xs"
        id="more-info"
        style={{ backgroundColor: "#fce9d9" }}
      >
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12">
              <h2 style={{ marginTop: "60px" }}>Informações Importantes</h2>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-3">
              <div class="block modal-instance">
                <a href="#" class="modal-trigger">
                  <div class="feature boxed boxed--border border--round text-center">
                    <i class="icon--lg icon-Box-Close"></i>
                    <span class="h5">Caixas</span>
                  </div>
                </a>
                <div class="modal-container">
                  <div class="modal-content section-modal">
                    <section class="unpad ">
                      <div class="container">
                        <div class="row justify-content-center">
                          <div class="col-md-8">
                            <div class="boxed boxed--lg bg--white">
                              <div class="modal-close modal-close-cross"></div>
                              <h3 class="text-center">CAIXAS</h3>
                              <div class="boxed bg--secondary boxed--lg boxed--border text-justify">
                                <h4>Contratante plano Comfort</h4>
                                <ul class="bullets">
                                  <li style={{ marginBottom: "1.5em" }}>
                                    Está incluso o recebimento de caixas (do
                                    enxoval) e conferência. Se as compras forem
                                    feitas pelo próprio cliente o mesmo deve
                                    enviar por e-mail o recibo com os itens e
                                    quantidades compradas;
                                  </li>
                                  <li>
                                    Para recebimento de produtos que não sejam
                                    de origem do serviço contratado será cobrado
                                    um valor $30,00 dólares por caixa e não
                                    haverá a cobrança extra pelo serviço de
                                    redirecionamento;
                                  </li>
                                </ul>
                                <h4>Demais planos contratados</h4>
                                <ul class="bullets">
                                  <li style={{ marginBottom: "1.5em" }}>
                                    Está incluso o recebimento de caixas (do
                                    enxoval) e conferência. Se as compras forem
                                    feitas pelo próprio cliente o mesmo deve
                                    enviar por e-mail o recibo com os itens e
                                    quantidades compradas e é de
                                    responsabilidade do cliente realizar a
                                    retirada em nosso escritório;
                                  </li>
                                  <li>
                                    Para recebimento de produtos que não sejam
                                    de origem do serviço contratado será cobrado
                                    um valor $30,00 dólares por caixa por um
                                    período de até 30 dias;
                                  </li>
                                </ul>

                                <p
                                  style={{ lineHeight: "1.2em" }}
                                  class="text-center"
                                >
                                  <small>
                                    * Em casos de extravio ou perda das caixas
                                    iremos ajudá-los a solucionar, porém
                                    informamos que é de responsabilidade do
                                    comprador arcar com possíveis prejuízos
                                    mediante desde serviço.
                                  </small>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="block modal-instance">
                <a href="#" class="modal-trigger">
                  <div class="feature boxed boxed--border border--round text-center">
                    <i class="icon--lg icon-Plane"></i>
                    <span class="h5">Envio</span>
                  </div>
                </a>
                <div class="modal-container">
                  <div class="modal-content section-modal">
                    <section class="unpad ">
                      <div class="container">
                        <div class="row justify-content-center">
                          <div class="col-md-8">
                            <div class="boxed boxed--lg bg--white text-justify">
                              <div class="modal-close modal-close-cross"></div>
                              <h3 class="text-center">
                                ENVIO DE ENXOVAIS À DISTÂNCIA
                              </h3>
                              <div class="boxed bg--secondary boxed--lg boxed--border">
                                <h4>Como Funciona?</h4>
                                <ul class="bullets">
                                  <li style={{ marginBottom: "1.5em" }}>
                                    O frete internacional não depende do local
                                    de destino da caixa e sim do peso. Por esse
                                    motivo só conseguimos informar o VALOR após
                                    realizar a pesagem das caixas.
                                  </li>
                                  <li style={{ marginBottom: "1.5em" }}>
                                    Quando realizamos a pesagem informamos ao
                                    cliente o valor total e o mesmo irá realizar
                                    o pagamento.
                                  </li>
                                  <li>
                                    No momento que postamos enviamos para os
                                    nossos clientes o código de rastreamento,
                                    onde ele pode acompanhar o status da caixa
                                    em tempo real.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="block modal-instance">
                <a href="#" class="modal-trigger">
                  <div class="feature boxed boxed--border border--round text-center">
                    <i class="icon--lg icon-Handshake"></i>
                    <span class="h5">Tributação</span>
                  </div>
                </a>
                <div class="modal-container">
                  <div class="modal-content section-modal">
                    <section class="unpad ">
                      <div class="container">
                        <div class="row justify-content-center">
                          <div class="col-md-8">
                            <div class="boxed boxed--lg bg--white">
                              <div class="modal-close modal-close-cross"></div>
                              <h3 class="text-center">TRIBUTAÇÃO!</h3>
                              <div class="boxed bg--secondary boxed--lg boxed--border text-justify">
                                <ul class="bullets">
                                  <li style={{ marginBottom: "1.5em" }}>
                                    Iremos compactar o envio dos produtos para
                                    diminuir os volumes.
                                  </li>
                                  <li style={{ marginBottom: "1.5em" }}>
                                    Caso seja autorizado pelo cliente
                                    retiraremos todas as etiquetas dos produtos.
                                  </li>
                                  <li>
                                    Se não for tributado, a caixa chegará em seu
                                    endereço final; Caso seja tributado o
                                    cliente deverá entrar no site dos correios
                                    Brasil, informar o código de rastreio e
                                    imprimir o boleto para pagamento. Após
                                    realizado pagamento sua encomenda será
                                    liberada e seguirá para o seu endereço
                                    final.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="block modal-instance">
                <a href="#" class="modal-trigger">
                  <div class="feature boxed boxed--border border--round text-center">
                    <i class="icon--lg icon-Calendar"></i>
                    <span class="h5">Agendamentos</span>
                  </div>
                </a>
                <div class="modal-container">
                  <div class="modal-content section-modal">
                    <section class="unpad ">
                      <div class="container">
                        <div class="row justify-content-center">
                          <div class="col-md-8">
                            <div class="boxed boxed--lg bg--white text-justify">
                              <div class="modal-close modal-close-cross"></div>
                              <h3 class="text-center">AGENDAMENTOS</h3>
                              <p>
                                Os agendamentos deverão ser realizados através
                                de e-mail (contato@laisapersonalshopper.com.br)
                                caso a sua data seja muito especifica por favor
                                verifique a disponibilidade da data. Para
                                garantir o seu agendamento é necessário efetuar
                                50% do valor total da assessoria, através do
                                link de contratação do serviço.
                              </p>
                              <p>
                                Informamos que a data só será reservada após
                                compensação do pagamento e envio das informações
                                de DATA e LOCAL da assessoria.
                              </p>
                              <p>
                                Para conseguirmos atendê-lo com total
                                execelência seguindo todos os processos pedimos
                                que a assessoria será agendada com no mínimo 30
                                dias antes da data almejada.
                              </p>
                              <p>
                                A Laísa Personal Shopper não realiza
                                ressarcimento dos valores pagos por assessoria.
                                Seguem abaixo as nossas regras:
                              </p>
                              <div class="boxed bg--secondary boxed--lg boxed--border">
                                <ul class="bullets">
                                  <li>
                                    Caso o cliente nos informe sobre necessidade
                                    de alteração da data ou de plano com 30 dias
                                    antes da data agendada podemos atender a
                                    solicitação;
                                  </li>
                                  <li>
                                    Caso o cliente tenha necessidade de
                                    alteração de data ou de plano por motivos de
                                    saúde devidamente comprovado via documento
                                    formal iremos reagendar ou adequar a outro
                                    plano para melhor atendê-lo.
                                  </li>
                                  <li>
                                    Caso o cliente tenha necessidade de
                                    alteração de data ou de plano por motivos de
                                    falência de parentes diretos comprovado via
                                    documento formal iremos reagendar ou adequar
                                    a outro plano para melhor atendê-lo.
                                  </li>
                                  <li>
                                    Em casos de desistências em condições não
                                    citadas acima os valores PG pela reserva da
                                    data não serão ressarcidos e também não será
                                    considerado para uma nova data.
                                  </li>
                                  <li>
                                    Caso a Empresa Laísa Personal Shopper não
                                    possa atender por qualquer motivo o valor da
                                    assessoria será ressarcido em totalidade +
                                    multa 15% pelo transtorno causado.
                                  </li>
                                </ul>
                                <p>
                                  NOTA – Informamos que não é do nosso intuito
                                  prejudicar ou lesar o nosso cliente, porém a
                                  Laísa Personal Shopper é uma Empresa que
                                  oferece serviços por horas e um dia que
                                  perdemos ocasiona prejuízo para a Empresa e
                                  reforçamos que SEMPRE tomaremos as melhores
                                  decisões com o intuito de beneficiar à ambas
                                  as partes.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="block modal-instance">
                <a href="#" class="modal-trigger">
                  <div class="feature boxed boxed--border border--round text-center">
                    <i class="icon--lg icon-Clock"></i>
                    <span class="h5">Horas Contratadas</span>
                  </div>
                </a>
                <div class="modal-container">
                  <div class="modal-content section-modal">
                    <section class="unpad ">
                      <div class="container">
                        <div class="row justify-content-center">
                          <div class="col-md-8">
                            <div class="boxed boxed--lg bg--white text-justify">
                              <div class="modal-close modal-close-cross"></div>
                              <h3 class="text-center">HORAS CONTRATADAS</h3>
                              <div class="boxed bg--secondary boxed--lg boxed--border">
                                <ul class="bullets">
                                  <li style={{ marginBottom: "1.5em" }}>
                                    As horas contratadas serão contabilizadas a
                                    partir do momento acordado em reunião de
                                    planejamento. Caso o atraso seja por nossa
                                    parte, iremos repor as horas do atraso.
                                  </li>
                                  <li style={{ marginBottom: "1.5em" }}>
                                    Aos planos que possuem o serviço de
                                    transporte as horas são contabilizadas a
                                    partir do momento que o cliente se encontra
                                    com a assessora.
                                  </li>
                                  <li style={{ marginBottom: "1.5em" }}>
                                    Informamos que o trajeto entre lojas são
                                    contabilizados nas horas contratadas.
                                  </li>
                                  <li>
                                    Caso exista a necessidade da realização de
                                    horas adicionais e tenhamos disponibilidade
                                    de atendê-lo será realizada a cobrança de
                                    $60,00 dólares por hora que deverão ser
                                    pagas em Cash ao término da assessoria.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default informations;
