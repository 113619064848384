import React from "react";
import Menu from "../Home/components/menu";
import Footer from "../Home/components/footer";
import Services from "../Home/components/services";

import { Container, Content } from "./styles";

function getSteps() {
  return ["Dados Pessoais", "Pagamento", "Conclusão"];
}

export default function Plans({ ...rest }) {
  return (
    <>
      <Container>
        <Menu {...rest} />

        <Content>
          <Services {...rest} />
        </Content>
      </Container>
      <Footer></Footer>
    </>
  );
}
