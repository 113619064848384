import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
export const Content = styled.div`
  justify-content: center;
  align-items: center;

  /* background-color: red; */
  /* width: 100%; */
  flex: 1;
  padding: 10px 10% 0px;
  border-width: 2px 0px 0px;
  border-style: solid;
  border-color: #fddab8;
`;
