import React from "react";

// import { Container } from './styles';

function midias() {
  return (
    <>
      <section class="text-center bg--secondary" id="photos">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="heading-block">
                <h2>Nossos Canais de Comunicação</h2>
              </div>
              <a
                class="btn btn--icon bg--instagram type--uppercase"
                style={{ backgroundColor: "#f4bb81" }}
                href="https://www.instagram.com/laisa_personalshopper"
                target="_blank"
              >
                <span class="btn__text">
                  <i class="socicon socicon-instagram"></i>
                  @laisa_personalshopper
                </span>
              </a>
              <div class="row">
                <span></span>
              </div>
              <div
                class="instafeed"
                data-user-name="laisa_personalshopper"
                data-amount="12"
                data-grid="6"
              ></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default midias;
