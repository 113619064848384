import React from "react";

// import { Container } from './styles';

function footer() {
  return (
    <>
      <footer
        class="footer-7 text-center-xs bg--dark"
        style={{ backgroundColor: "#f4bb81" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <span
                class="type--fine-print"
                style={{ fontWeight: 600, opacity: 1 }}
              >
                ©<span class="update-year">2019</span> Laísa Personal Shopper —
                All Rights Reserved
              </span>
            </div>

            <div class="col-md-6 text-right text-center-xs">
              <ul class="social-list list-inline">
                <li>
                  <a href="#">
                    <i class="socicon socicon-google icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-twitter icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-facebook icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/laisa_personalshopper"
                    target="_blank"
                  >
                    <i class="socicon socicon-instagram icon icon--xs"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default footer;
