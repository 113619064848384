import React, { useState, useEffect } from "react";

//imagens
import logoCabioReal from "../../../assets/cambioreal.jpg";

import { DivForm, ContentButton } from "../styles";

import {
  Container,
  ContentMethods,
  ContentCard,
  ContainerInformation,
  ConteinerCardCredit,
  DivInputsCard,
  DivInput,
  DivMesAno,
  DivButton,
  ContentBoleto,
} from "./styles";

// import { Container } from './styles';

function FormPagSeguro({ ...props }) {
  const {
    setPaymentMethod,
    client,
    product,
    haddleSubmit,
    methodsAvaliable,
  } = props;
  const [card, setCard] = useState();
  const [auxCard, setAuxCard] = useState();
  const [brand, setBrand] = useState();
  const [numberCard, setNumberCard] = useState(null);
  const [showCardCredit, setShowCardCredit] = useState(false);

  const [showCardBoleto, setShowCardBoleto] = useState(false);

  useEffect(() => {
    isShowCard();
  }, []);

  const isShowCard = () => {
    methodsAvaliable.map((m) => {
      console.log(m);

      if (m == 1) {
        console.log(true);
        setShowCardBoleto(true);
      }

      if (m == 2) {
        console.log(true);
        setShowCardCredit(true);
      }
    });
  };

  return (
    <Container>
      <DivForm>
        <ContentMethods>
          <ContentCard
            show={showCardCredit}
            method={2}
            onClick={() => setPaymentMethod(2)}
          >
            <img
              style={{ height: 100 }}
              src="https://codigoemagrecerdevez.com.br/wp-content/uploads/2016/02/cartaodecredito.ico"
            ></img>
            <div
              style={{
                borderStyle: "solid",
                borderWidth: 2,
                textAlign: "center",
                padding: 10,
              }}
            >
              Cartão Credito
            </div>
          </ContentCard>
          <ContentBoleto
            show={showCardBoleto}
            method={1}
            onClick={() => setPaymentMethod(1)}
          >
            <img
              style={{ height: 100 }}
              src="https://sintepav-pe.org.br/wp-content/uploads/2016/03/boleto-icon.jpg.jpg"
            ></img>
            <div
              style={{
                borderStyle: "solid",
                borderWidth: 2,
                textAlign: "center",
                padding: 10,
              }}
            >
              Boleto Bancario
            </div>
          </ContentBoleto>
        </ContentMethods>
      </DivForm>
      <ContainerInformation>
        <div>
          <p>Nome: {client.name}</p>
          <p>Endereco:{client.address}</p>
          <p>Forma de pagamento:Boleto</p>
          {/* <p>Nome: Fernando</p>
          <p>Endereco:Rua marechal deodoto da fonseca</p>
          <p>Forma de pagamento: Cartão de credito </p> */}
        </div>
        <div>
          {product != null ? (
            <div>
              <p>Serviço: {product.name}</p>
              <p>Valor: $ {product.value},00</p>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div></div>
      </ContainerInformation>
      <ContainerInformation>
        <ConteinerCardCredit>
          <DivInputsCard>
            <p>Pagamento via Boleto será realizado através do Cambio Real.</p>
          </DivInputsCard>
          <div>
            <a href="https://www.cambioreal.com/">
              <img style={{ height: 50 }} src={logoCabioReal}></img>
            </a>
          </div>
        </ConteinerCardCredit>
      </ContainerInformation>
      <DivButton onClick={haddleSubmit}>Gerar Boleto</DivButton>
    </Container>
  );
}

export default FormPagSeguro;
