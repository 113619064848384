import React, { Fragment, useState, useRef, useEffect } from "react";
import axios from "axios";
import { Scope } from "@unform/core";
import { Form } from "@unform/web";
import Input from "../../components/input";
import Select from "../../components/select";
import Button from "@material-ui/core/Button";
import AccountBox from "@material-ui/icons/AccountBox";
import PaymentIcon from "@material-ui/icons/Payment";
import VideoLabelIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import { setLocale } from "yup";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


import Menu from "../Home/components/menu";
import Footer from "../Home/components/footer";
import { estados, countys } from "../../constants/constants";

//imagens
import cambioreal from "../../assets/cambioreal.jpg";
import banner from "../../assets/banner-payment.jpg";
import bannerTop from "../../assets/payment-banner-top.png";
import IconSuccess from "../../assets/success-icon.jpg";
import IconWhatsapp from "../../assets/whatsapp.jpg";
import IconDenied from "../../assets/icone-negado.jpg";

//componentes externos da pagina
import Step from "../../components/steps";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";

//componentes interos da pagina
import FormPagSeguro from "./components/FormPagseguro";
import FormCambioReal from "./components/FormCambioReal";
import FormMercadoPago from "./components/FormMercadoPago";

import { testCPF } from "../../util/functions";

//API
import api from "../../services/api";

import {
  Container,
  Content,
  StepPayment,
  DivButton,
  LogoCabioReal,
  ContentButton,
  DivInput,
  DESCRIPTION,
  CONTAINERDESCRIPTION,
  ButtonMoreDescription,
  DivForm,
  Banner,
  DivInputIn,
} from "./styles";
const icons = {
  1: <AccountBox />,
  2: <PaymentIcon />,
  3: <VideoLabelIcon />,
};

function getSteps() {
  return ["Dados Pessoais", "Pagamento", "Finalizar Pagamento"];
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getEstados() {
  let valueSelectStates = [];
  estados.map((item) => {
    let state = {
      value: item.sigla,
      label: item.nome,
    };

    valueSelectStates.push(state);
  });

  return valueSelectStates;
}

const Payment = ({ history, ...props }) => {
  console.log(JSON.stringify(history));
  const { location } = history;

  const [activeStep, setActiveStep] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [progress, setProgress] = useState(0);
  const [states, setStates] = useState(getEstados);
  const [citys, setCitys] = useState([]);
  const [cityActive, setCityActive] = useState(true);
  const [defaultValueState, setDefaultValueState] = useState();
  const [defaultValueCity, setDefaultValueCity] = useState({});
  const [products, setProducts] = useState([]);
  const [client, setClient] = useState(null);
  const [productSelect, setProductSelect] = useState(null);
  const [methodsAvaliable, setMethodsAvaliable] = useState([]);

  const [descriptionProduct, setDescriptionProduct] = useState([]);
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const [visibleDescription, setVisibleDescription] = useState(false);
  const [activeError, setActiveError] = useState(false);
  const [statusTransaction, setStatusTransaction] = useState("");
  const [loading, setLoading] = useState(false);
  const [typeMessage, setTypeMessage] = useState("error");
  const [phone, setPhone] = useState("");
  const [boleto, setBoleto] = useState("");
  const [urlContato, setUrlContato] = useState(
    "https://api.whatsapp.com/send?phone=5581992744711&text=OI%20gostaria%20de%20realizar%20o%20agendamento."
  );

  const [error, setError] = useState("");

  useEffect(() => {
    // Atualiza o titulo do documento usando a API do browser
    const data = {
      personal_key:
        "1EEB863CE69E67D606F8E524A75D0AE07A624EA8DFBD9C65948F3BAE03BEB3C6",
    };

    if (props.match.params.id != undefined) {
      verifyTransacrion(props.match.params.id);
    }

    async function getProduct() {
      const produtoslist = [];
      const p = await api.post("products/show", data);

      console.log(p);

      p.data.map((item) => {
        let coin = item.currency == "USD" ? "$" : "R$";
        let state = {
          value: item.id,
          label: `${item.name} ( ${coin} ${item.value}) `,
          description: item.description,
          name: item.name,
          valor: item.value,
          qtd: 1,
        };
        produtoslist.push(state);
      });
      setProducts(produtoslist);
    }

    setCitys(() => {
      let valueSelectCountys = [];
      countys.map((item) => {
        let county = {
          value: item.nome,
          label: item.nome,
        };

        valueSelectCountys.push(county);
      });
      return valueSelectCountys;
    });

    getProduct();
    setActiveStep(0);
  }, []);

  async function verifyTransacrion(idTransacrion) {
    const data = {
      personal_key:
        "1EEB863CE69E67D606F8E524A75D0AE07A624EA8DFBD9C65948F3BAE03BEB3C6",
      reference_id: idTransacrion,
    };
    const host = window.location.hostname;
    console.log("Verificando transacao");
    let response = await api.post("service/show", data);

    if (response.data != undefined && response.data.method == 1) {
      let urlContato = `https://api.whatsapp.com/send?phone=5581992744711&text=Olá%20gostaria%20de%20realizar%20o%20agendamento.%0ARealizei%20o%20pagamento%20pelo%20site.%0Apedido:%20https://${host}/payment/${idTransacrion}`;
      setActiveStep(4);
      setBoleto(response.data);
      setUrlContato(urlContato);
    } else if (response.data !== undefined && response.data.method == 2) {
      let urlContato = `https://api.whatsapp.com/send?phone=5581992744711&text=Olá%20gostaria%20de%20realizar%20o%20agendamento.%0ARealizei%20o%20pagamento%20pelo%20site.%0Apedido:%20https://${host}/payment/${idTransacrion}`;
      setUrlContato(urlContato);
      setActiveStep(2);
      setStatusTransaction(response.data);
      setPaymentMethod(response.data.method);
    }
  }

  const steps = getSteps();
  const formRef = useRef(null);

  async function handleSubmit(data, { reset }) {
    if (
      window.confirm(`Olá, ${data.name} ultilizaremos seus dados para gerar a cobrança.
                      Deseja confirmar os dados preenchidos?`)
    ) {
    
      setActiveError(false);
      try {
        setLocale({
          mixed: {
            default: "Não é válido",
          },
          number: {
            default: "Apenas Numeros",
          },
        });
        // Remove all previous errors
        formRef.current.setErrors({});

          if (defaultValueState == undefined) {
          setError("Estado Obrigatório");
          setActiveError(true);
        }
        if (defaultValueCity == undefined) {
          setError("Cidade Obrigatório");
          setActiveError(true);
        }

         if (phone == undefined || phone=="") {
          setError("Tekefone Obrigátorio");
          setActiveError(true);
        }
        const schema = Yup.object().shape({
          product: Yup.string().required("Produto Obrigatório"),
          name: Yup.string().required("Nome Completo obrigatório"),
          cpf: Yup.string()
            .min(11, "Minimo 11 numeros")
            .required("CPF Obrigátorio"),
          email: Yup.string().required("E-mail obrigatóio").email(),
          confirmEmail: Yup.string().oneOf(
            [Yup.ref("email"), null],
            "E-mail não confere verifique."
          ),
          birth: Yup.date("Data Invalida").required(
            "Data de Nascimento Obrigatório."
          ),
          address: Yup.object({
            zipcode: Yup.string()
              .min(8, "Minimo 08 numeros")
              .required("Cep Obrigatório"),
            street: Yup.string()
              .max(100, "Maximo 100 caracteres")
              .required("Cep Obrigatório"),
            neighborhood: Yup.string()
              .max(20, "Maximo 20 caracteres")
              .required("Cep Obrigatório"),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        // Validation passed

      

        setClient({
          cpf: data.cpf,
          name: data.name,
          email: data.email,
          birth: data.birth,
          phone: phone,
          zipcode: data.address.zipcode,
          address: data.address.street,
          number: data.address.number,
          state: defaultValueState.value,
          city: defaultValueCity.value,
          complemet: data.address.complement,
        });

       
        products.map((item) => {
          if (item.value === data.product) {
            item.id = item.value;
            item.value = item.valor;
            setProductSelect(item);
          }
        });

        const requestProduct = {
          personal_key:
            "1EEB863CE69E67D606F8E524A75D0AE07A624EA8DFBD9C65948F3BAE03BEB3C6",
          product_id: data.product,
        };

        console.log(requestProduct);
        console.log(data.product);
        let response = await api.post("/products/payment", requestProduct);

        if (response.data.status == true) {
          setMethodsAvaliable(response.data.dados);
          setPaymentMethod(response.data.dados[0]);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          setError(response.data.error);
          setActiveError(true);
        }
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  }

  async function finishPayment(data) {
    const productRequest = [];
    productRequest.push(productSelect);
    const dataCreate = {
      personal_key:
        "1EEB863CE69E67D606F8E524A75D0AE07A624EA8DFBD9C65948F3BAE03BEB3C6",
      method_id: paymentMethod,
      client: client,
      products: productRequest,
      installments: data.installments,
      card: {
        number: data.number,
        exp_month: data.exp_month,
        exp_year: data.exp_year,
        security_code: data.security_code,
        holder: {
          name: data.name,
        },
      },
      valorTotal: data.valueTotal,
    };

    console.log(dataCreate);

    setLoading(true);
    try {
      let responseCreate = await api.post("service/create", dataCreate);
      console.log(responseCreate.data);
      setLoading(false);
      if (responseCreate.data.status === "PAID") {
        setStatusTransaction(responseCreate.data);
        setError(
          "Pagamento Realizado com " +
            responseCreate.data.message +
            " Codigo de autorização: " +
            responseCreate.data.code
        );
        setTypeMessage("success");

        setActiveError(true);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        const id = responseCreate.data.id;
        const host = window.location.hostname;
        let urlContato = `https://api.whatsapp.com/send?phone=5581992744711&text=Olá%20gostaria%20de%20realizar%20o%20agendamento.%0ARealizei%20o%20pagamento%20pelo%20site.%0Apedido:%20https://${host}/payment/${id}`;
        setUrlContato(urlContato);
      } else {
        setTypeMessage("error");
        if (responseCreate.data.status == "error") {
          setError(responseCreate.data.error);
        } else {
          setError("Transação não autorizada: " + responseCreate.data.message);
        }
        setActiveError(true);
      }
    } catch (error) {
      console.log(error);
      if (error.status != undefined && error.status == "error") {
        setError(error.description);
      } else {
        setError("Ocorreu um erro Inesperado!!");
      }
      setTypeMessage("error");
      setActiveError(true);
      setLoading(false);
    }
  }

  async function finishPaymentCambioReal(data) {
    const productRequest = [];
    productRequest.push(productSelect);
    const dataCreate = {
      personal_key:
        "1EEB863CE69E67D606F8E524A75D0AE07A624EA8DFBD9C65948F3BAE03BEB3C6",
      method_id: paymentMethod,
      client: client,
      products: productRequest,
    };
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 2
      );
    }, 1000);
    try {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      let responseCreate = await api.post("service/create", dataCreate);
      console.log(responseCreate.data);

      if (responseCreate.data.status === "AGUARDANDO_CLIENTE") {
        redirect(responseCreate, timer);
      } else {
        console.log(responseCreate.data);
        clearInterval(timer);
        if (responseCreate.data.status == "error") {
          setError(responseCreate.data.error);
        } else {
          setError(
            "Não foi possivel gerar o boleto: " + responseCreate.data.message
          );
        }
        setActiveError(true);
      }
    } catch (error) {
      clearInterval(timer);
      setError(error.response.data.error);
      setActiveError(true);
    }
  }

  async function finishPaymentMercadoPago(data) {
    const productRequest = [];
    productRequest.push(productSelect);
    const dataCreate = {
      personal_key:
        "1EEB863CE69E67D606F8E524A75D0AE07A624EA8DFBD9C65948F3BAE03BEB3C6",
      method_id: paymentMethod,
      client: client,
      products: productRequest,
    };
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 2
      );
    }, 1000);
    try {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      let responseCreate = await api.post("service/create", dataCreate);
      console.log(responseCreate.data);

      if (responseCreate.data.status === "OPEN") {
        redirect(responseCreate, timer);
      } else {
        console.log(responseCreate.data);
        clearInterval(timer);
        if (responseCreate.data.status == "error") {
          setError(responseCreate.data.error);
        } else {
          setError(
            "Não foi possivel Efetuar o Pagamento: " +
              responseCreate.data.message
          );
        }
        setActiveError(true);
      }
    } catch (error) {
      clearInterval(timer);
      setError(error.response.data.error);
      setActiveError(true);
    }
  }

  const redirect = (responseCreate, timer) => {
    window.location.href = responseCreate.data.checkout;
    clearInterval(timer);
    setProgress(100);
  };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const getCountys = (stateSelect) => {
    let valueSelectCountys = [];
    countys.map((item) => {
      if (item.microrregiao.mesorregiao.UF.sigla === stateSelect.value) {
        let county = {
          value: item.nome,
          label: item.nome,
        };

        valueSelectCountys.push(county);
      }
    });

    setDefaultValueState(stateSelect);
    setCityActive(false);
    setCitys(valueSelectCountys);
  };

  const selectCity = (citySelect) => {
    setDefaultValueCity(citySelect);
  };

  const getDescriptionSelect = (productSelect) => {
    products.map((item) => {
      if (item.value === productSelect) {
        const descriptions = item.description.split(";");

        setDescriptionProduct(descriptions);
        setDescriptionVisible(true);
      }
    });
  };

  const formPagSeguroRef = useRef(null);
  const getAddress = async (zipcode) => {
    axios
      .get(`https://viacep.com.br/ws/${zipcode}/json/`)
      .then(function (response) {
        console.log(response.data); // ex.: { user: 'Your User'}
        console.log(response.status); // ex.: 200

        if ((response.status === 200) & (response.data.erro !== true)) {
          formRef.current.setFieldValue(
            "address.street",
            response.data.logradouro
          );
          formRef.current.setFieldValue(
            "address.neighborhood",
            response.data.bairro
          );

          setDefaultValueState(
            states.filter((option) => option.value === response.data.uf)[0]
          );

          getCountys(
            states.filter((option) => option.value === response.data.uf)[0]
          );

          setDefaultValueCity(
            citys.filter(
              (option) => option.value === response.data.localidade
            )[0]
          );
        } else {
          formRef.current.setFieldValue("address.street", "");
          formRef.current.setFieldValue("address.neighborhood", "");

          setDefaultValueState("");

          setDefaultValueCity("");
        }
      });
    // const allData = formRef.current.getData();
    // const a = formRef.current.getFieldValue("address.zipcode");
    // formRef.current.setFieldValue("address.state", a);
  };

  const activeDescription = () => {
    setVisibleDescription(!visibleDescription);
  };

  const handdleCloseAlert = () => {
    setActiveError(false);
  };

  const handdleSetPaymentMethod = (idMethod) => {
    setPaymentMethod(idMethod);
  };

  return (
    <>
      <Container activeStep={activeStep}>
        <Menu {...props} />

        <Snackbar
          style={{ fontSize: 20 }}
          open={activeError}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handdleCloseAlert} severity={typeMessage}>
            {error}
          </Alert>
        </Snackbar>
        {/* <Banner src={bannerTop}></Banner> */}
        <Step icons={icons} activeStep={activeStep} steps={steps} />

        <Content>
          {activeStep === steps.length ? (
            <div></div>
          ) : (
            <Fragment>
              {activeStep === 0 ? (
                <DivForm>
                  <Form onSubmit={handleSubmit} ref={formRef}>
                    <Select
                      name="product"
                      placeholder="Selecione o serviço desejado"
                      classNamePrefix="react-select"
                      options={products}
                      onChange={(e) => getDescriptionSelect(e.value)}
                    />
                    <ButtonMoreDescription
                      visible={descriptionVisible}
                      onClick={activeDescription}
                    >
                      <Fab color="#fae9d7" aria-label="add">
                        <AddIcon />
                      </Fab>
                    </ButtonMoreDescription>
                    {descriptionVisible ? (
                      <CONTAINERDESCRIPTION visible={visibleDescription}>
                        {descriptionProduct.map((item) => (
                          <DESCRIPTION>
                            <VideoLabelIcon style={{ color: "#ebbf99" }} />
                            <spam>{item}</spam>
                          </DESCRIPTION>
                        ))}
                      </CONTAINERDESCRIPTION>
                    ) : (
                      <div></div>
                    )}

                    <DivInput>
                      <DivInputIn>
                        <Input name="name" placeholder="Nome" />
                      </DivInputIn>
                      <DivInputIn>
                        <Input name="cpf" placeholder="CPF" />
                      </DivInputIn>
                      <DivInputIn>
                        {/* <Input
                          name="phone"
                          placeholder="Celular: 5521998545950"
                          type="number"
                          /> */}

                           <PhoneInput
                  country={'br'}
                  value={phone}
                  onChange={phoneChange => setPhone(phoneChange)}
                  name={"phone"}
                  inputProps={{
                    name: "phone",
                    enableSearch: true,
                    required: true,
                  }}
                  />
                      </DivInputIn>
                  </DivInput>
                          
                    <DivInput>
                      <DivInputIn>
                        <Input name="email" placeholder="E-mail" type="email" />
                      </DivInputIn>
                      <DivInputIn>
                        <Input
                          name="confirmEmail"
                          placeholder="Confirme E-mail"
                          type="email"
                        />
                      </DivInputIn>
                    </DivInput>
                    <DivInputIn>
                      <Input
                        name="birth"
                        label="Data Nascimento"
                        placeholder="Data Nascimento"
                        type="date"
                      />
                    </DivInputIn>

                    <Scope path="address">
                      <DivInput>
                        <DivInputIn>
                          <Input
                            name="zipcode"
                            path="zipcode"
                            placeholder={"CEP Ex:00000000"}
                            onBlur={(e) => getAddress(e.target.value)}
                            type="number"
                          />
                        </DivInputIn>
                        <DivInputIn>
                          <Input name="street" placeholder="Rua" />
                        </DivInputIn>
                        <DivInputIn>
                          <Input name="neighborhood" placeholder="Bairro" />
                        </DivInputIn>
                      </DivInput>
                      <DivInput>
                        <DivInputIn>
                          <Input
                            name="number"
                            placeholder="numero"
                            type="number"
                          />
                        </DivInputIn>
                        <DivInputIn>
                          <Input name="complement" placeholder="complemento" />
                        </DivInputIn>
                      </DivInput>

                      <Select
                        name="state"
                        placeholder="Estado"
                        value={defaultValueState}
                        classNamePrefix="react-select"
                        options={states}
                        onChange={(e) => getCountys(e)}
                      />

                      <Select
                        name="county"
                        placeholder="Cidade"
                        value={defaultValueCity}
                        classNamePrefix="react-select"
                        options={citys}
                        isDisabled={cityActive}
                        onChange={(e) => selectCity(e)}
                      />
                    </Scope>

                    <ContentButton>
                      <DivButton>
                        {activeStep === 0 ? (
                          <Button type={onsubmit}>proximo</Button>
                        ) : (
                          <div></div>
                        )}
                      </DivButton>
                    </ContentButton>
                  </Form>
                </DivForm>
              ) : (
                <div></div>
              )}
            </Fragment>
          )}

          {(activeStep == 1) & (paymentMethod == 2) ? (
            <FormPagSeguro
              onSubmit={handleSubmit}
              formPagSeguroRef={formPagSeguroRef}
              setPaymentMethod={handdleSetPaymentMethod}
              client={client}
              product={productSelect}
              finishPayment={finishPayment}
              loading={loading}
              methodsAvaliable={methodsAvaliable}
            />
          ) : (
            <div></div>
          )}

          {(activeStep == 1) & (paymentMethod == 1) ? (
            <FormCambioReal
              setPaymentMethod={handdleSetPaymentMethod}
              client={client}
              product={productSelect}
              haddleSubmit={finishPaymentCambioReal}
              methodsAvaliable={methodsAvaliable}
            />
          ) : (
            <div></div>
          )}

          {(activeStep == 1) & (paymentMethod == 3) ? (
            <FormMercadoPago
              setPaymentMethod={handdleSetPaymentMethod}
              client={client}
              product={productSelect}
              haddleSubmit={finishPaymentMercadoPago}
              methodsAvaliable={methodsAvaliable}
            />
          ) : (
            <div></div>
          )}

          {(activeStep == 2) & (paymentMethod == 1) ? (
            <DivForm>
              <StepPayment>
                <div>
                  <CircularProgressWithLabel value={progress} />
                </div>
                <div>
                  <h3>
                    {" "}
                    Estamos redirecionando para pagina de pagamento Câmbio Real.
                  </h3>
                  <h4>Favor concluir o processo para gerar o boleto.</h4>
                </div>
                <div>
                  <LogoCabioReal src={cambioreal}></LogoCabioReal>
                </div>
              </StepPayment>
            </DivForm>
          ) : (
            <div></div>
          )}

          {(activeStep == 2) & (paymentMethod == 3) ? (
            <DivForm>
              <StepPayment>
                <div>
                  <CircularProgressWithLabel value={progress} />
                </div>
                <div>
                  <h3>
                    {" "}
                    Estamos redirecionando para pagina de pagamento Mercado
                    Pago.
                  </h3>
                </div>
                <div>
                  <LogoCabioReal src="https://http2.mlstatic.com/resources/frontend/landings-mp/images/mercadopago-og.jpg"></LogoCabioReal>
                </div>
              </StepPayment>
            </DivForm>
          ) : (
            <div></div>
          )}

          {(activeStep == 2) & (paymentMethod == 2) ? (
            <DivForm>
              <StepPayment>
                <div>
                  <h1>Laisa Personal Shopper agradece sua preferência</h1>
                </div>
                {statusTransaction.status === "PAID" ? (
                  <div>
                    <div>
                      <LogoCabioReal src={IconSuccess}></LogoCabioReal>
                    </div>

                    <div>
                      <h2>Pagamento Realizado com Sucesso!!</h2>
                    </div>
                    <div>
                      <h3>Codigo de autorização:{statusTransaction.code}</h3>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        justifyItems: "space-around",
                      }}
                    >
                      <p>Entre em contato para realizar o agendamento </p>
                      <a href={urlContato}>
                        <img
                          style={{ height: 30, marginLeft: 5 }}
                          src={IconWhatsapp}
                        ></img>
                      </a>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <LogoCabioReal
                        style={{ height: 100, width: 100 }}
                        src={IconDenied}
                      ></LogoCabioReal>
                    </div>

                    <div>
                      <h3>INFELIZMENTE SEU PAGAMENTO NÃO FOI AUTORIZADO</h3>
                    </div>
                    <div>
                      <h4>{statusTransaction.message}</h4>
                    </div>
                  </div>
                )}

                <div></div>
              </StepPayment>
            </DivForm>
          ) : (
            <div></div>
          )}

          {(activeStep == 4) & (paymentMethod == 1) ? (
            <DivForm>
              <StepPayment>
                <StepPayment>
                  <div>
                    <h1>Agradecemos sua preferência</h1>
                  </div>
                  <div>
                    <LogoCabioReal src={IconSuccess}></LogoCabioReal>
                  </div>

                  <div>
                    <h2>Estamos aguardando a confirmação de pagamento. </h2>
                  </div>
                  <div>
                    <p>Status pagamento:{boleto.status}</p>
                    <a href={boleto.boleto}>
                      <p>Baixar Boleto</p>
                    </a>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      justifyItems: "space-around",
                    }}
                  >
                    <p>
                      Entre em contato para realizar o agendamento, após
                      realização do pagamento.{" "}
                    </p>

                    <a href={urlContato}>
                      <img
                        style={{ height: 30, marginLeft: 5 }}
                        src={IconWhatsapp}
                      ></img>
                    </a>
                  </div>
                  <div></div>
                </StepPayment>
              </StepPayment>
            </DivForm>
          ) : (
            <div></div>
          )}
        </Content>
        {/* <Banner src={banner}></Banner> */}
        <Footer></Footer>
      </Container>
    </>
  );
};

export default Payment;
