import React from "react";

// import { Container } from './styles';

function contact() {
  return (
    <>
      <section class="switchable space--xs" id="contact">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-md-12">
              <h2>Entre em Contato</h2>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-md-6">
              <div>
                <h3>Escritório Sede</h3>
              </div>
              <div class="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.7303993987716!2d-80.14474818488279!3d25.779467083628738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b48a7f0b3401%3A0x200502b6e5b097a2!2s900+West+Ave%2C+Miami+Beach%2C+FL+33139%2C+EUA!5e0!3m2!1spt-BR!2sbr!4v1551359825724"
                  width="600"
                  height="450"
                  frameborder="0"
                  style={{ border: 0 }}
                  allowfullscreen
                ></iframe>
              </div>

              <br />
            </div>
            <div class="col-md-6 col-12">
              {/* <div class="row mx-0 switchable__text flex-column"> */}
              {/* <form
                  class="form-email row"
                  action="www.laisapersonalshopper.com.br/personalShopperService/enviarContato"
                  data-success="Obrigado, entraremos em contato em breve."
                  data-error="Por favor, preencha todos os campos corretamente."
                >
                  <div class="col-md-6">
                    <label>Seu Nome:</label>
                    <input type="text" name="nome" class="validate-required" />
                  </div>
                  <div class="col-md-6">
                    <label>Seu Email:</label>
                    <input
                      type="email"
                      name="remetente"
                      class="validate-required validate-email"
                    />
                  </div>
                  <div class="col-md-12">
                    <label>Mensagem:</label>
                    <textarea
                      rows="4"
                      name="mensagem"
                      class="validate-required"
                    ></textarea>
                  </div>
                  <div class="col-md-5 col-lg-4">
                    <button
                      type="submit"
                      class="btn btn--primary type--uppercase"
                      style={{ backgroundColor: "#f4bb81" }}
                    >
                      Enviar
                    </button>
                  </div>
                </form> */}
              {/* </div> */}
              <br />
              <h5>atendimento@laisapersonalshopper.com.br</h5>
              <h5>
                Entraremos em contato em até 24h após recebimento do seu e-mail.
              </h5>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default contact;
