import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";

export default function Input({ name, label, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue = "", registerField, error } = useField(name);
  const [color, setColor] = useState("#ffff");

  useEffect(() => {
    {
      error ? setColor("#FFF0F5") : setColor("#ffff");
    }
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField, error]);
  return (
    <>
      {label && <label htmlFor={fieldName}>{label}</label>}
      {error && <span style={{ color: "#f00" }}>{error}</span>}

      <input
        style={{ padding: 10, backgroundColor: color }}
        ref={inputRef}
        id={fieldName}
        defaultValue={defaultValue}
        {...rest}
      />
    </>
  );
}
