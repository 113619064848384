import React from "react";

import Menu from "./components/menu";
import Banner from "./components/banner";
import About from "./components/about";
import howFuction from "./components/howFunction";
import Services from "./components/services";
import Depositions from "./components/depositions";
import Informations from "./components/informations";
import Midias from "./components/midias";
import Social from "./components/social";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Team from "./components/team";

const Home = ({ ...rest }) => {
  return (
    <>
      <Menu {...rest} />
      <div class="main-container">
        <Banner />
        <About />
        <howFuction />
        <Services {...rest} />
        <Depositions />
        <Informations />
        <Midias />
        <Social />
        <Team />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default Home;
