import React from "react";

// import { Container } from './styles';

function services({ history }) {
  async function payment(product) {
    // dispatch(selectDelivery(delivery));
    history.push("/payment", product);
  }
  return (
    <>
      <section id="plans" class="text-center">
        <h2>Conheça Nossos Planos e Serviços</h2>
        <div class="tabs-container" data-content-align="left">
          <ul class="tabs tabs--spaced">
            <li class="active">
              <div class="tab__title text-center">
                <i class="icon icon--sm block icon-Baby-Clothes2"></i>
                <span class="h5">Enxoval de bebê</span>
              </div>
              <div class="tab__content">
                <div class="container">
                  <div class="row ">
                    <div class="col-sm-6">
                      <div class="pricing pricing-1 boxed boxed--border boxed--2g text-center">
                        <h4>Basic</h4>
                        <span class="h1">
                          <span class="pricing__dollar">$</span>300
                          <span class="pricing__coin"> dolares</span>
                        </span>
                        <ul class="text-justify">
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Reunião de planejamento.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Lista personalizada.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Serviço de até 4h de assessoria em um dia para
                              compras de todos os acessórios ou roupinhas.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                        <h4>Smart</h4>
                        <span class="h1">
                          <span class="pricing__dollar">$</span>400
                          <span class="pricing__coin"> dolares</span>
                        </span>
                        <ul class="text-justify">
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Reunião de planejamento.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Lista personalizada.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Serviço de até 6h de assessoria em um dia para
                              compras de todos os acessórios e roupinhas em no
                              máximo 3 lojas.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                        <h4>Complete</h4>
                        <span class="h1">
                          <span class="pricing__dollar">$</span>500
                          <span class="pricing__coin"> dolares</span>
                        </span>
                        <ul class="text-justify">
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Reunião de planejamento.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Lista personalizada.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Serviço inclui dois dias em compras de até 4h
                              diárias para compra de enxoval completo.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                        <h4>Vip</h4>
                        <span class="h1">
                          <span class="pricing__dollar">$</span>600
                          <span class="pricing__coin"> dólares</span>
                        </span>
                        <ul class="text-justify">
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Reunião de planejamento &amp; lista personalizada.
                            </span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Serviço inclui dois dias em compras de até 4h
                              diárias para compra de enxoval completo.
                            </span>
                          </li>

                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Arrumação das malas do enxoval inclusas (1 saco à
                              vacuo grande incluso). OBS: Caso seja de interesse
                              do nosso cliente adquirir malas de saco temos em
                              pronta entrega pelo valor de $30 dólares que
                              deverá ser pago em cash a consultora.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="tab__title text-center">
                <i class="icon icon--sm block icon-Baby-Clothes"></i>
                <span class="h5">Enxoval de Kids</span>
              </div>
              <div class="tab__content">
                <div class="container">
                  <div class="row justify-content-around">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                        <h4>Smart Kids</h4>
                        <span class="h1">
                          <span class="pricing__dollar">$</span>400
                          <span class="pricing__coin"> dolares</span>
                        </span>
                        <ul class="text-justify">
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Reunião de planejamento.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Lista personalizada.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Serviço de até 5h de assessoria em um dia para
                              compras de todos os acessórios e roupinhas em no
                              máximo 3 lojas.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                        <h4>Complete Kids</h4>
                        <span class="h1">
                          <span class="pricing__dollar">$</span>450
                          <span class="pricing__coin"> dolares</span>
                        </span>
                        <ul class="text-justify">
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Reunião de planejamento.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Lista personalizada.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Serviço inclui dois dias em compras de até 4h
                              diárias para compra de enxoval completo.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                        <h4>Vip Kids</h4>
                        <span class="h1">
                          <span class="pricing__dollar">$</span>500
                          <span class="pricing__coin"> dolares</span>
                        </span>
                        <ul class="text-justify">
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Reunião de planejamento &amp; lista personalizada.
                            </span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Serviço inclui dois dias em compras de até 4h
                              diárias para compra de enxoval completo.
                            </span>
                          </li>

                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Arrumação das malas do enxoval inclusas (1 saco à
                              vacuo grande incluso). OBS: Caso seja de interesse
                              do nosso cliente adquirir malas de saco temos em
                              pronta entrega pelo valor de $30 dólares que
                              deverá ser pago em cash a consultora.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="tab__title text-center">
                <i class="material-icons block">phonelink_ring</i>
              </div>
              <span class="h5">Comfort</span>
              <div class="tab__content">
                <div class="container">
                  <div class="col-sm-12 col-md-6 col-lg-4">
                    <div class="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                      <h4>Comfort</h4>
                      <span class="h1">
                        <span class="pricing__dollar">$</span>500
                        <span class="pricing__coin"> dolares</span>
                      </span>
                      <ul class="text-justify">
                        <li>
                          <span class="checkmark bg--primary-1"></span>
                          <span>Assessoria para enxovais à distância.</span>
                        </li>
                        <li>
                          <span class="checkmark bg--primary-1"></span>
                          <span>
                            Reunião de planejamento e lista personalizada.
                          </span>
                        </li>
                        <li>
                          <span class="checkmark bg--primary-1"></span>
                          <span>
                            Inclui até 8h de assessoria para compras de
                            assessórios e roupinhas em lojas físicas e online.
                          </span>
                        </li>

                        <li>
                          <span class="checkmark bg--primary-1"></span>
                          <span>
                            Taxa de redirecionamento por caixa - $30 dólares.
                            Caso seja de interesse do cliente que as roupinhas
                            estejam em saco à vacúo terá custo adiciona de $5
                            dólares unid.
                          </span>
                        </li>
                        <li>
                          <span class="checkmark bg--primary-1"></span>
                          <span>
                            Para enxovais de gêmeos será cobrada taxa adicional
                            de $100 dólares.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="tab__title text-center">
                <i class="icon icon--sm block icon-Girl"></i>
                <span class="h5">Personalizado</span>
              </div>
              <div class="tab__content">
                <div class="container">
                  <div class="row">
                    <div class="col-md-8">
                      <h4 class="text-center">
                        Oferecemos também o serviço sem acompanhamento das
                        assessoras. <br />
                        Ideal para quem planeja viajar para uma Cidade ou
                        Estados que não oferecemos atendimento.
                      </h4>
                    </div>
                  </div>
                  <div class="row justify-content-around">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                        <span class="h1">
                          <span class="pricing__dollar">$</span>200
                          <span class="pricing__coin"> dolares</span>
                        </span>
                        <ul class="text-justify">
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Reunião de planejamento</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Lista personalizada</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Dicas de lojas</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Cupons (Mediante disponibilidade)</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Lista de compras online</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Suporte via e-mail</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="tab__title text-center">
                <i class="icon icon--sm block icon-Conference"></i>
                <span class="h5">Friends</span>
              </div>
              <div class="tab__content">
                <div class="container">
                  <div class="row justify-content-around">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                        <h4>Friends Basic</h4>
                        <span class="h1">
                          <span class="pricing__dollar">$</span>500
                          <span class="pricing__coin"> dolares</span>
                        </span>
                        <ul class="text-justify">
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Reunião de planejamento.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Lista personalizada.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Serviço de até 5h de assessoria em um dia para
                              compras de todos os acessórios ou roupinhas.
                            </span>
                          </li>

                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Serviço disponível para bebê do mesmo sexo ou sexo
                              oposto.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                        <h4>Friends Complete</h4>
                        <span class="h1">
                          <span class="pricing__dollar">$</span>800
                          <span class="pricing__coin"> dolares</span>
                        </span>
                        <ul class="text-justify">
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Reunião de planejamento.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>Lista personalizada.</span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Serviço inclui dois dias em compras de até 5h
                              diárias para compra de enxoval completo.
                            </span>
                          </li>

                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Serviço disponível apenas para bebês do mesmo
                              sexo.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="tab__title text-center">
                <i class="icon icon--sm block icon-Girl"></i>
                <span class="h5">Arrumação de Malas</span>
              </div>
              <div class="tab__content">
                <div class="container">
                  <div class="row justify-content-around">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                        <ul class="text-justify">
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Arrumação de malas com malo saco inclusa para
                              grandes volumes $150,00 dólares.
                            </span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Arrumação de mala sem mala inclusa $100,00
                              dólares.
                            </span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Na arrumação está incluso 1 saco à vácuo por mala.
                              Adicionais terão custo de $5 dólares unid.
                            </span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              As malas estarão de acordo com o peso solicitado e
                              todos os produtos devidamente vedados.
                            </span>
                          </li>
                          <li>
                            <span class="checkmark bg--primary-1"></span>
                            <span>
                              Devem ser retiradas 48h após realização das
                              compras no endereço do nosso escritório Orlando ou
                              Miami.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="pricing pricing-1 boxed boxed--lg text-center">
          {/* <a href="/payment"> */}
          <div
            onClick={() =>
              payment({
                id: 1,
              })
            }
            class="btn btn--primary "
          >
            <span class="btn__text">Contrate Agora</span>
            <i class="material-icons block">card</i>
          </div>
          {/* </a> */}
        </div>

        <div class="row" style={{ marginTop: "2em" }}>
          <div class="col-md-8">
            <spam>
              Caso seja de interesse do cliente contratar horas adicionais
              deverá ser consultado disponibilidade com a consultora responsável
              e terá o custo de $60 dólares por hora.
            </spam>
          </div>
        </div>
      </section>
    </>
  );
}

export default services;
