import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
export const Content = styled.div`
  justify-content: center;
  align-items: center;

  /* background-color: red; */
  /* width: 100%; */
  flex: 1;
  padding: 10px 10% 0px;
  border-width: 2px 0px 0px;
  border-style: solid;
  border-color: #fddab8;
`;

export const ContentButton = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  /* margin: 0 auto; */
  width: 100%;
  /* background-color: #fddab8; */
  margin-top: 10px;
`;

export const DivButton = styled.div`
  width: 10%;
  background-color: #fddab8;
  margin-bottom: 10px;
  border-radius: 10%;
  font-weight: bold;
  &:hover {
    background: #ba55d3;
  }
  @media (max-width: 600px) {
    width: 25%;
  }
`;

export const StepPayment = styled.div`
  display: flex;
  text-align: Center;
  /* background-color: blue; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50vh;
  padding: 20px;

  /* background-color: red; */
`;

export const LogoCabioReal = styled.img`
  width: 40%;
  @media (max-width: 600px) {
    width: 40%;
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  justify-content: right;
  align-items: center;
  /* border-style: solid; */
  /* border-width: 1px; */
  padding: 5px;
  border-radius: 2%;
  input {
    width: 30%;
    margin-top: 5px;
    margin-right: 4px;

    border-width: 0px 0px 2px;
    border-color: darkgray;
    &::placeholder {
      text-align: center;
    }

    &:focus {
      background: #d3d3d3;
    }
  }
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    input {
      width: 100%;
    }
  }
`;
