import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import Home from "./../pages/Home";
import Payment from "./../pages/Payment";
import Plans from "./../pages/Plans";
import Depositions from "./../pages/Depositions";
import Contact from "./../pages/Contact";
import Midias from "./../pages/midias";
// import Home from "./../pages/teste";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/payment" exact component={Payment} />
      <Route path="/payment/:id" component={Payment} />
      <Route path="/plans" exact component={Plans} />
      <Route path="/depositions" exact component={Depositions} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/midias" exact component={Midias} />
    </Switch>
  );
}
