import React from "react";

// import { Container } from './styles';

function banner() {
  return (
    <>
      <section class="cover height-60 text-center imagebg" data-overlay="1">
        <div class="background-image-holder">
          <img alt="background" src="img/capa.jpg" style={{ opacity: "50%" }} />
        </div>
        <div class="container pos-vertical-center">
          <div class="row justify-content-around">
            <div class="col-lg-5 col-md-7">
              <div class="switchable__text">
                <h1 style={{ color: "#756677" }}>
                  Enxoval de Bebê nos <strong>Estados Unidos</strong>
                </h1>
                <p class="lead text-justify" style={{ color: "#7d654b" }}>
                  Temos como missão proporcionar aos nossos clientes
                  experiências únicas e inesquecíveis através de nossos serviços
                  de assessoria em Compras nos Estados Unidos.
                </p>

                <a
                  class="btn type--uppercase btn-video"
                  href="#"
                  data-modal-index="0"
                >
                  <span class="btn__text">▶ Assista o Vídeo</span>
                </a>
              </div>
            </div>
            <div class="col-lg-6 col-md-4 col-12">
              <div class="video-cover border--round box-shadow-wide">
                <div class="background-image-holder">
                  <img alt="image" src="img/bg-video.png" />
                </div>
                <div class="video-play-icon"></div>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/KJo8NxAO4v8"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="text-center cta cta-4 space--xxs border--bottom imagebg"
        data-gradient-bg="#fddab8"
      >
        <canvas id="granim-2" width="1440" height="80"></canvas>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <span
                class="label label--inline"
                style={{ backgroundColor: "#f4bb81", color: "white" }}
              >
                Estamos localizados Em
              </span>
              <span
                class="label label--inline"
                style={{ backgroundColor: "#f4bb81", color: "white" }}
              >
                <strong>Miami, Orlando e Nova York</strong>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default banner;
