import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";

const Styles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function Menu() {
  const classes = Styles();
  const [side, setSide] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setSide({ ...side, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        className="bar__module"
        style={{ marginTop: "10px", marginLeft: "10px", fontSize: 20 }}
      >
        <ul className="menu-horizontal text-left">
          <li className="dropdown">
            <a className="dropdown__trigger" href="/">
              Home
            </a>
          </li>
          <Divider></Divider>
          <li className="dropdown">
            <a className="dropdown__trigger" href="/plans">
              Planos
            </a>
          </li>
          <Divider></Divider>
          <li className="dropdown">
            <a className="dropdown__trigger" href="/depositions">
              Depoimentos
            </a>
          </li>
          <Divider></Divider>
          <li className="dropdown">
            <a className="dropdown__trigger" href="/midias">
              Na mídia
            </a>
          </li>
          <Divider></Divider>
          <li className="dropdown">
            <a className="dropdown__trigger" href="/contact">
              Contato
            </a>
          </li>
          <Divider></Divider>
        </ul>
      </div>
    </div>
  );

  return (
    <>
      <a id="start"></a>
      <div className="nav-container ">
        <div className="bar bar--sm visible-xs">
          <div className="container">
            <div className="row">
              <div className="col-3 col-md-2">
                <a href="/">
                  <img
                    className="logo logo-dark"
                    alt="logo"
                    src="/img/logo.png"
                  />
                  <img
                    className="logo logo-light"
                    alt="logo"
                    src="/img/logo.png"
                  />
                </a>
              </div>
              <div className="col-9 col-md-10 text-right">
                <div
                  onClick={toggleDrawer("left", true)}
                  class="hamburger-toggle"
                  data-toggle-className="#menu1;hidden-xs"
                >
                  <i className="icon icon--sm stack-interface stack-menu"></i>
                </div>

                <Drawer
                  anchor={"left"}
                  open={side["left"]}
                  onClose={toggleDrawer("left", false)}
                >
                  {list("left")}
                </Drawer>
              </div>
            </div>
          </div>
        </div>

        <nav id="menu1" className="bar bar--sm bar-1 hidden-xs ">
          <div className="container">
            <div className="row">
              <div className="col-lg-1 col-md-2 hidden-xs">
                <div className="bar__module">
                  <a href="/">
                    <img
                      className="logo logo-dark"
                      alt="logo"
                      src="/img/logo.png"
                    />
                    <img
                      className="logo logo-light"
                      alt="logo"
                      src="/img/logo.png"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-11 col-md-12 text-right text-left-xs text-left-sm">
                <div className="bar__module" style={{ marginTop: "10px" }}>
                  <ul className="menu-horizontal text-left">
                    <li className="dropdown">
                      <a className="dropdown__trigger" href="/">
                        Home
                      </a>
                    </li>
                    <li className="dropdown">
                      <a className="dropdown__trigger" href="/plans">
                        Planos
                      </a>
                    </li>
                    <li className="dropdown">
                      <a className="dropdown__trigger" href="/depositions">
                        Depoimentos
                      </a>
                    </li>

                    <li className="dropdown">
                      <a className="dropdown__trigger" href="/midias">
                        Na mídia
                      </a>
                    </li>

                    <li className="dropdown">
                      <a className="dropdown__trigger" href="/contact">
                        Contato
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="bar__module"></div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
