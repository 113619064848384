import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./home-styles.css";

function depositions() {
  return (
    <>
      <div className="container-depositions">
        <div className="body">
          <h1>Depoimentos</h1>
          <Carousel autoPlay className="corousel">
            <div class="testimonial testimonial-2 row justify-content-center">
              <div class="col-lg-10 col-md-8 col-12 boxed boxed--border bg--secondary ">
                <span class="h3">
                  &ldquo;Foram dois dias apaixonantes. Amamos tudo, aproveitamos
                  muitos descontos, encontramos varias coisas com preço muito
                  bom e que ainda tinham descontos. Laísa teve que me segurar,
                  quase colocar uma venda nos olhos para não comprar além do
                  necessário, porque a gente fica enlouquecido, rsrs. Papais da
                  Manu super felizes e agradecidos pelo atendimento.&rdquo;
                </span>
                <h5>Fernanda</h5>
                <span>(Mamãe da Manuela)</span>
              </div>
            </div>
            <div class="testimonial row justify-content-center">
              <div class="col-lg-8 col-md-8 col-12 boxed boxed--border bg--secondary">
                <span class="h3">
                  &ldquo;Serviço indispensável, não conseguiria jamais fazer
                  isso sozinha! Muito obrigada!!! Muito realizada!!!&rdquo;
                </span>
                <h5>Louise Alencar</h5>
                <span>(Mamãe do Gael)</span>
              </div>
            </div>
            <div class="testimonial row justify-content-center">
              <div class="col-lg-10 col-md-8 col-12 boxed boxed--border bg--secondary">
                <span class="h3">
                  &ldquo;Laísa está com você na sua consultoria para o nosso
                  enxoval foi simplesmente SENSACIONAL, super indicamos o seu
                  trabalho!! Otimizamos o nosso tempo e ainda conseguimos ótimos
                  descontos. Só temos a agradecer por todas as explicações e
                  ajuda na escolha de cada peça. Você foi ESSENCIAL!!! E o Gui
                  também agradece, rs! Beijos!&rdquo;
                </span>
                <h5>Silvio Galvão</h5>
                <span>(Papai do Guilherme)</span>
              </div>
            </div>
            <div class="testimonial row justify-content-center">
              <div class="col-lg-8 col-md-8 col-12 boxed boxed--border bg--secondary">
                <span class="h3">
                  &ldquo;Adoramos seu trabalho Laísa!!! Sem você seria muito
                  difícil escolher tanta coisa linda! Obrigada, beijos!&rdquo;
                </span>
                <h5>Elide Gaban</h5>
                <span>(Vovó do Rafael)</span>
              </div>
            </div>
            <div class="testimonial row justify-content-center">
              <div class="col-lg-8 col-md-8 col-12 boxed boxed--border bg--secondary">
                <span class="h3">
                  &ldquo;Não temos como agradecer esse momento que dividimos
                  contigo! Você merece todos os clientes do mundo! Muito sucesso
                  Laísa! Nossa Nina já te ama!&rdquo;
                </span>
                <h5>Lis Croccia</h5>
                <span>(Mamãe da Marina)</span>
              </div>
            </div>
            <div class="testimonial row justify-content-center">
              <div class="col-lg-9 col-md-8 col-12 boxed boxed--border bg--secondary">
                <span class="h3">
                  &ldquo;Meu Deus você tem muito bom gosto. Estou
                  satisfeitíssima com todo o enxoval do Bento, não vejo a hora
                  de vê tudo pessoalmente. Só temos a te agradecer por TUDO, eu
                  com certeza não conseguiria fazer metade de tudo que você fez.
                  MUITO OBRIGADA por tudo!&rdquo;
                </span>
                <h5>Taynan Lima</h5>
                <span>(Mamãe do Bento)</span>
              </div>
            </div>
            <div class="testimonial row justify-content-center">
              <div class="col-lg-9 col-md-8 col-12 boxed boxed--border bg--secondary">
                <span class="h3">
                  &ldquo;Ameeeeeeeeei! Como eu te disse sem você eu entraria na
                  buybuybaby (entre outras lojas) e ao olhar para essa imensidão
                  de coisas, sentaria no chão e choraria... hahaha. Assessoria
                  fundamental , além de ótima cia. Super recomendo!&rdquo;
                </span>
                <h5>Monique Lima</h5>
                <span>(Mamãe de Manuela)</span>
              </div>
            </div>
            <div class="testimonial row justify-content-center">
              <div class="col-lg-10 col-md-8 col-12 boxed boxed--border bg--secondary">
                <span class="h3">
                  &ldquo;Queria declarar minha admiração por Laisa...
                  literamente ela foi um anjo para mim e para o Pedro. Só
                  agradecer a está pessoa incrível , sem contar que compre
                  muita, muita coisa até os 2 anos, em média , do meu Pedro. Obg
                  tia Laísa , te aguardamos aqui na terrinha amada. Beijos com
                  sabor de saudades e alegria.&rdquo;
                </span>
                <h5>Taise</h5>
                <span>(Mamãe do Pedrinho)</span>
              </div>
            </div>
            <div class="testimonial row justify-content-center">
              <div class="col-lg-10 col-md-8 col-12 boxed boxed--border bg--secondary">
                <span class="h3">
                  &ldquo;Queria declarar minha admiração por Laisa...
                  literamente ela foi um anjo para mim e para o Pedro. Só
                  agradecer a está pessoa incrível , sem contar que compre
                  muita, muita coisa até os 2 anos, em média , do meu Pedro. Obg
                  tia Laísa , te aguardamos aqui na terrinha amada. Beijos com
                  sabor de saudades e alegria.&rdquo;
                </span>
                <h5>Taise</h5>
                <span>(Mamãe do Pedrinho)</span>
              </div>
            </div>
            <div class="testimonial row justify-content-center">
              <div class="col-lg-10 col-md-8 col-12 boxed boxed--border bg--secondary">
                <span class="h3">
                  &ldquo;Laísa, mais uma vez, obrigada por tudo! Ter você com a
                  gente fez toda a diferença! Poupamos tempo e dinheiro! Sem
                  contar que você é uma querida, super atenciosa, nos ajudou nas
                  escolhas como se fosse para alguém da sua família! Pode ter
                  certeza de que vou te indicar para todo mundo!&rdquo;
                </span>
                <h5>Livia</h5>
                <span>(Mamãe do Thomas)</span>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default depositions;
