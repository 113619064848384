import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
export const Content = styled.div`
  background-color: #fff5ed;

  width: 100%;
  flex: 1;
  padding: ${(props) => (props.activeStep == "0" ? "10px 10% 0px" : "0px")};
  border-width: 2px 0px 0px;
  border-style: solid;
  border-color: #fddab8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DivForm = styled.div`
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
  padding: 20px;
  border-radius: 5%;
  margin: 10px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    width: 100%;
    padding: 0px;
    padding-top: 10px;
    margin: 0px;
    margin-bottom: 10px;
  }
`;
export const ContentButton = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  /* margin: 0 auto; */
  width: 100%;
  /* background-color: #fddab8; */
  margin-top: 10px;
`;

export const DivButton = styled.div`
  width: 20%;
  background-color: #fddab8;
  margin-bottom: 10px;
  border-radius: 10%;
  font-weight: bold;
  &:hover {
    background: #fae9d7;
  }
  @media (max-width: 600px) {
    width: 40%;
  }
`;

export const StepPayment = styled.div`
  display: flex;
  text-align: Center;
  /* background-color: blue; */
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  min-height: 50vh;
  padding: 20px;

  /* background-color: red; */
`;

export const CONTAINERDESCRIPTION = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  background-color: #fae9d7;
  border-radius: 2%;

  /* background-color: red; */
  @media (max-width: 600px) {
    display: ${(props) => (props.visible === true ? "flex" : "none")};
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    background-color: #fae9d7;
    border-style: solid;
    border-width: 5px;
    border-color: #ffff;
    border-radius: 0%;
  }
`;

export const DESCRIPTION = styled.div`
  display: flex;
  color: black;

  background-color: #ffff;
  margin: 10px;
  padding: 10px;
  border-radius: 5%;
  width: 25%;
  align-items: center;
  flex-direction: column;
  border-style: solid;
  border-width: 2px;
  border-color: #fddab8;
  font-size: 14px;
  font-family: "Lato"; "Verdana"; "Helvetica"; sans-serif;
  min-height: 200px;

  &:hover {
    background: #dcdcdc;
    /* color: #ffff; */
    border-style: solid;
    border-width: 2px;
    border-color: #ffff;
  }

  /* background-color: red; */
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 40px;
    align-items: center;
    font-size: 10px;
  }
`;

export const LogoCabioReal = styled.img`
  height: 200px;
  @media (max-width: 600px) {
    height: 100px;
  }
`;

export const Banner = styled.img`
  width: 100%;
  margin-bottom: 0;
  /* @media (max-width: 600px) {
    width: 40%;
  } */
`;

export const ButtonMoreDescription = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: ${(props) => (props.visible ? "flex" : "none")};
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
`;

export const DivInput = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  /* margin-top: 5px; */
  justify-content: right;
  align-items: center;
  /* border-style: solid; */
  /* border-width: 1px; */
  width: 100%;
  padding: 5px;
  border-radius: 2%;
  label {
    margin-bottom: 0px;
  }
  input {
    width: 100%;

    margin-right: 4px;

    border-width: 0px 0px 2px;
    border-color: darkgray;
    &::placeholder {
      text-align: center;
    }

    &:focus {
      background: #fddab8;
    }
  }
  select {
    width: 100%;
    margin-top: 5px;
    margin-right: 4px;

    border-width: 0px 0px 2px;
    border-color: darkgray;
    &::placeholder {
      text-align: center;
    }

    &:focus {
      background: #d3d3d3;
    }
  }
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    input {
      width: 100%;
    }
  }
`;

export const DivInputIn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: center;
  width: 33%;
  padding: 3px;
  /* background-color: red; */

  @media (max-width: 600px) {
    width: 100%;
  }

  select {
    width: 100%;
    margin-top: 5px;
    margin-right: 4px;

    border-width: 0px 0px 2px;
    border-color: darkgray;
    &::placeholder {
      text-align: center;
    }

    &:focus {
      background: #d3d3d3;
    }
  }
`;
