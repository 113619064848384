import React from "react";

// import { Container } from './styles';

function team() {
  return (
    <>
      <section
        class="text-center space--xs"
        id="more-info"
        style={{ backgroundColor: "#fce9d9" }}
      >
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12">
              <h2 style={{ marginTop: "60px" }}>Nosso Time</h2>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-3">
              <div class="block modal-instance">
                <a href="#" class="modal-trigger">
                  <div class="feature boxed boxed--border border--round text-center">
                      <img style={{borderRadius: "50%"}} alt="image" src="img/juliana-atendimento.jfif" />
                    <span class="h5">Juliana (Atendimento)</span>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-md-3">
              <div class="block modal-instance">
                <a href="#" class="modal-trigger">
                <div class="feature boxed boxed--border border--round text-center">
                      <img style={{borderRadius: "50%"}} alt="image" src="img/ligia.jfif" />
                    <span class="h5">Ligia (Orlando)</span>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-md-3">
              <div class="block modal-instance">
                <a href="#" class="modal-trigger">
                <div class="feature boxed boxed--border border--round text-center">
                      <img style={{borderRadius: "50%"}} alt="image" src="img/mariana-nova-iorque.jfif" />
                    <span class="h5">Mariana (Nova York)</span>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-md-3">
              <div class="block modal-instance">
                <a href="#" class="modal-trigger">
                <div class="feature boxed boxed--border border--round text-center">
                      <img style={{borderRadius: "50%"}} alt="image" src="img/suellen-miami.jfif" />
                    <span class="h5">Suellen (Miami)</span>
                  </div>
                </a>
               
              </div>
            </div>
            <div class="col-md-3">
              <div class="block modal-instance">
                <a href="#" class="modal-trigger">
                <div class="feature boxed boxed--border border--round text-center">
                      <img style={{borderRadius: "50%"}} alt="image" src="img/natalia-chicago.jfif" />
                    <span class="h5">Natalia (Chicago)</span>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-md-3">
              <div class="block modal-instance">
                <a href="#" class="modal-trigger">
                <div class="feature boxed boxed--border border--round text-center">
                      <img style={{borderRadius: "50%"}} alt="image" src="img/ananda-portugal.jfif" />
                    <span class="h5">Ananda (Portugal)</span>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-md-3">
              <div class="block modal-instance">
                <a href="#" class="modal-trigger">
                <div class="feature boxed boxed--border border--round text-center">
                      <img style={{borderRadius: "50%"}} alt="image" src="img/Juliana-Aggio-Orlando.jfif" />
                    <span class="h5">Juliana Aggio (Orlando)</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default team;
