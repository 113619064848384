import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  min-height: 100vh;
  background-color: #ffff;
  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
  margin: 10px;
  justify-content: flex-start;
  @media (max-width: 600px) {
    width: 100%;
    padding: 5px;
    font-size: 15px;
  }
`;
export const ContentMethods = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const ContentCard = styled.div`
  padding: 20;
  display: ${(props) => (props.show == true ? "block" : "none")};
  background-color: ${(props) => (props.method === 2 ? "#abceff" : "#ffff")};

  border-radius: 10%;
  padding: 20px;
  border-width: 2px;
`;

export const ContentBoleto = styled.div`
  padding: 20;
  display: ${(props) => (props.show == true ? "block" : "none")};
  background-color: ${(props) => (props.method === 1 ? "#abceff" : "#ffff")};

  border-radius: 10%;
  padding: 20px;
  border-width: 2px;
`;

export const ContainerInformation = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-around;
  border-radius: 20px;
  border-style: outset;
  border-width: 2px;
  font-size: 20px;
  font-weight: bold;
  padding: 20px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    flex-direction: column;
    font-size: 12px;
    width: 100%;
  }
`;

export const ConteinerCardCredit = styled.div`
  display: flex;
  width: 100%;
  /* background-color: red; */
  justify-content: space-around;
  align-items: center;
  img {
    height: 200px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    font-size: 10;
    img {
      height: 100px;
    }
  }
`;

export const DivInputsCard = styled.div`
  display: flex;
  width: 50%;
  /* background-color: red; */
  /* justify-content: space-around;
  align-items: center; */
  form {
    width: 100%;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    font-size: 10;
    width: 100%;
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  justify-content: center;

  width: 100%;

  border-radius: 2%;
  input {
    margin-bottom: 3px;
    border-width: 0px 0px 2px;
    border-color: darkgray;
    text-align: center;

    &::placeholder {
      text-align: center;
    }

    &:focus {
      background: #d3d3d3;
    }
  }
  select {
    border-color: darkgray;
    border-style: solid;
    margin-bottom: 3px;
    font-size: 15px;
    padding: 5px;
    width: 100%;

    option {
      text-align: center;
      align-items: center;
      font-size: 20px;
      padding: 5px;
    }

    &::placeholder {
      text-align: center;
    }

    &:focus {
      background: #d3d3d3;
    }
  }
`;
export const DivMesAno = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  align-items: center;

  input {
    width: 100%;
    height: 100%;
  }
  select {
    text-align: center;
    width: 100%;
    border-color: darkgray;
    border-style: solid;
    height: 100%;

    option {
      align-items: center;
      font-size: 20px;
    }
  }
  div {
    width: 100%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    font-size: 10;
    width: 100%;

    div {
      width: 100%;
    }
  }
`;

export const DivButton = styled.div`
  width: 20%;
  background-color: #43cd80;
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #b4eeb4;
  }

  @media (max-width: 600px) {
    width: 40%;
  }
`;

export const DivImageCard = styled.div`
  @media (max-width: 600px) {
    padding-top: 20px;
  }
`;
