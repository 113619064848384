import React from "react";

// import { Container } from './styles';

function howFuction() {
  return (
    <>
      <section
        id="about"
        class="bg--secondary"
        style={{ backgroundColor: "#fce9d9", paddingTop: "7em" }}
      >
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12">
              <h2>Entenda Como Funciona!</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="feature feature-2 boxed boxed--border">
                <i class="icon icon-Mail-Send color--primary"></i>
                <div
                  class="feature__body text-justify"
                  style={{ height: "160px" }}
                >
                  <p>
                    <span class="color--primary">Primeiro Contato</span>
                    <br />
                    Envie um email e receba todas as instruções de como
                    contratar nossos serviços, informações de pagamento e
                    disponibilidade de agenda.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="feature feature-2 boxed boxed--border">
                <i class="icon icon-Receipt-3 color--primary"></i>
                <div
                  class="feature__body text-justify"
                  style={{ height: "160px" }}
                >
                  <p>
                    <span class="color--primary">Planejamento</span>
                    <br />
                    Enviaremos um material para o seu e-mail para preparação da
                    reunião de planejamento personalizada e definição de roteiro
                    de lojas.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="feature feature-2 boxed boxed--border">
                <i class="icon icon-Presents color--primary"></i>
                <div
                  class="feature__body text-justify"
                  style={{ height: "160px" }}
                >
                  <p>
                    <span class="color--primary">Compras online</span>
                    <br />
                    Você receberá uma lista de todos os itens, inclusive dos
                    itens para compras online. Nosso endereço será
                    disponibilizado para recebimento.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="feature feature-2 boxed boxed--border">
                <i class="icon icon-Handshake color--primary"></i>
                <div
                  class="feature__body text-justify"
                  style={{ height: "160px" }}
                >
                  <p>
                    <span class="color--primary">Assessoria presencial</span>
                    <br />
                    Estaremos juntos no dias das compras, assessorando sobre
                    todos os produtos e oferecemos descontos exclusivos nas
                    lojas.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="feature feature-2 boxed boxed--border">
                <i class="icon icon-Portrait color--primary"></i>
                <div
                  class="feature__body text-justify"
                  style={{ height: "160px" }}
                >
                  <p>
                    <span class="color--primary">Assessoria à distância</span>
                    <br />
                    Você acompanhará todas as compras via vídeo e será
                    assessorado sobre todos os produtos, além dos descontos
                    exclusivos nas lojas.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="feature feature-2 boxed boxed--border">
                <i class="icon icon-Suitcase color--primary"></i>
                <div
                  class="feature__body text-justify"
                  style={{ height: "160px" }}
                >
                  <p>
                    <span class="color--primary">Arrumação de malas</span>
                    <br />
                    Aos contratantes desse serviço levaremos todos os produtos
                    para o escritório e o cliente poderá retirar as malas no
                    prazo de 48h.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default howFuction;
