import React from "react";

// import { Container } from './styles';

function about() {
  return (
    <>
      <section
        class="switchable switchable--switch feature-large"
        style={{ paddingTop: "3em", paddingBbottom: "3em" }}
      >
        <div class="container">
          <div class="row justify-content-around">
            <div class="col-md-12">
              <div class="switchable__text">
                <h2>Quem Somos Nós!</h2>
                <img
                  alt="Image"
                  class="border--round"
                  src="img/laisa_executiva.jpeg"
                  style={{ width: "500px", float: "left", marginRight: "30px" }}
                />
                <p class="lead text-justify">
                  Somos uma Empresa liderada pela brasileira Laísa Melo,
                  Coaching em Neurociência e especialista em produtos do
                  segmento infantil. Estamos localizados nos Estados Unidos,
                  oferecemos serviço de assessoria em compras no segmento de
                  bebê e kids.
                </p>
                <p class="lead text-justify">
                  Temos como objetivo tornar o momento da chegada de um bebê uma
                  missão mais simples para os nossos clientes e de oferecer todo
                  o suporte na concretização do sonho de fazer o enxoval nos
                  Estados Unidos, com acesso aos melhores produtos e lançamentos
                  do Mercado respeitando sempre o orçamento dos nossos clientes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" class="bg--secondary" style={{ paddingTop: "7em" }}>
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12">
              <h2>Por que contratar?</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="feature feature-2 boxed boxed--border">
                <div class="text-center" style={{ height: "160px" }}>
                  <div>
                    <h4>Atendimento personalizado</h4>
                    <p style={{ maxWidth: "100%", minHeight: "0px" }}>
                      Consultoras treinadas e respeitadas no mercado para
                      atendê-los de acordo com as suas necessidade e orçamento
                      desde o momento da primeira reunião de planejamento.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="feature feature-2 boxed boxed--border">
                <div class="text-center" style={{ height: "160px" }}>
                  <div>
                    <h4>Economia</h4>
                    <p style={{ maxWidth: "100%", minHeight: "0px" }}>
                      Possuímos parcerias com as maiories lojas do segmento e
                      oferecemos descontos exclusivos aos nossos clientes tendo
                      em média de $800 dólares de economia, além de garantir a
                      compra dos produtos com assertividade, sem exageros e
                      respeitando sempre o orçamento.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="feature feature-2 boxed boxed--border">
                <div class="text-center" style={{ height: "160px" }}>
                  <div>
                    <h4>Praticidade</h4>
                    <p style={{ maxWidth: "100%", minHeight: "0px" }}>
                      Através da nossa assessoria você consegue economizar seu
                      tempo na hora das compras, aprender sobre os produtos e
                      forma de utilizar e aproveitar melhor a viagem!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default about;
