import React from "react";
import Menu from "../Home/components/menu";
import Footer from "../Home/components/footer";
import Body from "../Home/components/midias";

import { Container, Content } from "./styles";

const midias = ({ ...rest }) => {
  return (
    <>
      <Container>
        <Menu {...rest} />

        <Content>
          <Body />
        </Content>
        <Footer></Footer>
      </Container>
    </>
  );
};

export default midias;
