import React from "react";
import Menu from "../Home/components/menu";
import Footer from "../Home/components/footer";
import Body from "../Home/components/depositions";

import { Container, Content } from "./styles";

const Depositions = ({ ...rest }) => {
  return (
    <>
      <Container>
        <Menu {...rest} />

        <Content>
          <Body />
        </Content>

        <Footer></Footer>
      </Container>
    </>
  );
};

export default Depositions;
