import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./home-styles.css";

function midias() {
  return (
    <>
      <div className="container-depositions">
        <div className="body"></div>
        <h2 class="text-center">Na Mídia</h2>
        <Carousel
          autoPlay
          className="corousel"
          showArrows={true}
          showThumbs={true}
        >
          <div class="testimonial testimonial-2 row justify-content-center">
            <div class="col-lg-8 col-md-8 col-12 boxed boxed--border bg--secondary">
              <img style={{ height: 200 }} src="/img/Vogue.jpg"></img>
              <a href="https://vogue.globo.com/celebridade/noticia/2019/09/jonathan-azevedo-faz-enxoval-do-seu-primeiro-filho-em-orlando.html?233">
                <span class="h3">
                  &ldquo;Através das redes sociais, a personal shopper Laísa
                  Melo compartilhou clique do ator em uma loja fazendo as
                  comprinhas e elogiou encontro com o ator: "Conhecemos muitas
                  pessoas ao longo de nossas vidas, algumas que lembramos para
                  sempre e que nos transformam, às vezes só pelo exemplo outras
                  vezes com ações!&rdquo;
                </span>
              </a>
            </div>
          </div>

          <div class="testimonial testimonial-2 row justify-content-center">
            <div class="col-lg-8 col-md-8 col-12 boxed boxed--border bg--secondary">
              <img
                src="/img/Social1.jpg"
                style={{ height: 200, width: 300 }}
              ></img>
              <a href="https://blogs.ne10.uol.com.br/social1/2019/09/24/personal-shopper-recifense-laisa-melo-se-especializou-nos-enxovais-para-bebes-em-miami-e-orlando/">
                <span class="h3">
                  &ldquo;A recifense Laísa Melo, 28 anos, não é mãe, mas diz
                  saber tudo o que as mães precisam. É que, vivendo nos Estados
                  Unidos, mas com vindas frequentes à capital pernambucana, ela
                  era constantemente aproveitada pelos amigos para um
                  favorzinho: “traz isso”, “traz aquilo”. Entre os pedidos,
                  coisas para bebês, dada a fama de “paraíso dos enxovais” que
                  os States ostentam. Por artigos de bebê ela sempre foi
                  interessada, e então uniu lé com cré, o útil ao agradável,
                  estudou o mercado e há pouco mais de um ano tornou negócio
                  aquilo que fazia por favor.&rdquo;
                </span>
              </a>
            </div>
          </div>

          <div
            class="testimonial testimonial-2 row justify-content-center"
            style={{ width: "100%" }}
          >
            <div class="col-lg-8 col-md-8 col-12 boxed boxed--border bg--secondary">
              <img src="/img/jrs.jpg" style={{ height: 200, width: 200 }}></img>
              <a href="https://jrs.digital/newsdino/?title=contratar-um-personal-shopper-virou-moda-entre-os-brasileiros-e-garante-praticidade-e-economia-na-hora-de-montar-o-enxoval-do-bebe&partnerid=1482&releaseid=202469">
                <span class="h3">
                  &ldquo;Especialista Laísa Melo mostra os 5 produtos mais
                  procurados pelos brasileiros nos EUA; economia pode chegar até
                  $ 700,00 dólares por compra.&rdquo;
                </span>
              </a>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  );
}

export default midias;
