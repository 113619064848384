import React, { useState, useEffect, useRef } from "react";

import { Form } from "@unform/web";
import Input from "../../../components/input";
import Select from "@material-ui/core/Select";

import { cards } from "../../../constants/constants";

//imagens
import cardAmex from "../../../assets/cartao-america.jpg";
import cardDiners from "../../../assets/cartao-diners.jpg";
import cardHiperCard from "../../../assets/cartao-hipercard.png";
import cardMasterCard from "../../../assets/cartão-mastercard.jpg";
import cardVisa from "../../../assets/cartao-visa.jpg";
import cardAura from "../../../assets/cartao-aura.jpg";
import cardElo from "../../../assets/cartao-elo.png";
import cardCVV from "../../../assets/cartao-cvv.jpg";
import cardCredit from "../../../assets/cartao-credito.png";

//Componentes

import BackDrop from "../../../components/BackDrop";

import { DivForm } from "../styles";
import {
  Container,
  ContentMethods,
  ContentCard,
  ContainerInformation,
  ConteinerCardCredit,
  DivInputsCard,
  DivInput,
  DivMesAno,
  DivButton,
  DivImageCard,
} from "./styles";

// import { Container } from './styles';

function FormPagSeguro({ ...props }) {
  const {
    finishPayment,
    formPagSeguroRef,
    setPaymentMethod,
    client,
    product,
    loading,
    methodsAvaliable,
  } = props;
  const formRef = useRef(null);
  const [card, setCard] = useState(cardCredit);
  const [auxCard, setAuxCard] = useState(null);
  const [brand, setBrand] = useState(null);
  const [numberCard, setNumberCard] = useState(null);
  const [installments, setInstallments] = useState({});
  const [installmentsValue, setInstallmentsValue] = useState(null);
  const [monthValue, setMonthValue] = useState("");
  const [valueTotalFormat, setvalueTotalFormat] = useState("");
  const [valueFormat, setvalueFormat] = useState("");

  const [installmentsOption, setInstallmentsOption] = useState([]);
  const [showCardCredit, setShowCardCredit] = useState(false);

  const [showCardBoleto, setShowCardBoleto] = useState(false);

  useEffect(() => {
    isShowCard();
    // window.PagSeguroDirectPayment.setSessionId(
    //   "ID_DA_SESSÃO_OBTIDO_NO_PASSO_1"
    // );
    console.log(document);
    window.PagSeguroDirectPayment.setSessionId(
      "ef690b7c8d7d452c9a6429d834856147"
    );

    setvalueFormat(
      product.value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    );

    // window.PagSeguroDirectPayment.getPaymentMethods({
    //   amount: 500.0,
    //   success: function (response) {
    //     // Retorna os meios de pagamento disponíveis.
    //     console.log("success");
    //     console.log(response);
    //   },
    //   error: function (response) {
    //     // Callback para chamadas que falharam.
    //     console.log("error");
    //     console.log(response);
    //   },
    //   complete: function (response) {
    //     // Callback para todas chamadas.
    //   },
    // });

    //     document.PagSeguroDirectPayment.getBrand({
    //       cardBin: 411111,
    //       success: function (response) {
    //         //bandeira encontrada
    //         console.log(response);
    //       },
    //       error: function (response) {
    //         //tratamento do erro
    //         console.log(response);
    //       },
    //       complete: function (response) {
    //         //tratamento comum para todas chamadas
    //       },
    //     });

    //     document.PagSeguroDirectPayment.getInstallments({
    //       amount: 2000,
    //       // maxInstallmentNoInterest: 2,
    //       brand: "visa",
    //       success: function (response) {
    //         // Retorna as opções de parcelamento disponíveis
    //         console.log(response);
    //       },
    //       error: function (response) {
    //         // callback para chamadas que falharam.
    //         console.log(response);
    //       },
    //       complete: function (response) {
    //         // Callback para todas chamadas.
    //       },
    //     });
  }, []);

  const selectInstallments = (option) => {
    console.log(option);
    installmentsOption.map((p) => {
      if (option == p.quantity) {
        console.log("Entrou");
        console.log(p);

        console.log();
        setvalueTotalFormat(
          p.totalAmount.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        );
        setInstallments(p);
        setInstallmentsValue(option);
      }
    });
  };

  const selectMonth = (month) => {
    setMonthValue(month);
  };

  const submitForm = () => {
    formRef.current.onSubmit();
  };

  const handdleSubmit = () => {
    let data = formRef.current.getData();

    // console.log(data);
    // console.log(installments.quantity);
    // console.log(installments.totalAmount);

    data.installments = installments.quantity;
    data.valueTotal = installments.totalAmount;
    data.exp_month = monthValue;
    console.log(data);
    finishPayment(data);
  };

  const getInstallments = () => {
    if (product.value != null && product.value > 0 && brand != null) {
      window.PagSeguroDirectPayment.getInstallments({
        amount: product.value,
        // amount: 100,
        brand: brand.toLowerCase(),
        success: function (response) {
          // Retorna as opções de parcelamento disponíveis
          console.log(response);
          if (response.installments != undefined) {
            if (brand.toLowerCase() === "visa") {
              setInstallmentsOption(response.installments.visa);
            }
            if (brand.toLowerCase() === "amex") {
              setInstallmentsOption(response.installments.amex);
            }
            if (brand.toLowerCase() === "elo") {
              setInstallmentsOption(response.installments.elo);
            }
            if (brand.toLowerCase() === "hipercard") {
              setInstallmentsOption(response.installments.hipercard);
            }
            if (brand.toLowerCase() === "diners") {
              setInstallmentsOption(response.installments.diners);
            }
            if (brand.toLowerCase() === "mastercard") {
              setInstallmentsOption(response.installments.mastercard);
            }
            if (brand.toLowerCase() === "aura") {
              setInstallmentsOption(response.installments.aura);
            }
          }
        },
        error: function (response) {
          // callback para chamadas que falharam.
          console.log(response);
        },
        complete: function (response) {
          // Callback para todas chamadas.
        },
      });
    }
  };

  const selectCard = (number) => {
    setBrand("");
    setCard(cardCredit);
    if (number.length > 16) {
      number = number.substring(0, 16);

      setNumberCard(number);
    }
    number = number.replace(".", "");
    if (number.length >= 1) {
      cards.visa.map((n) => {
        if (n == number.substring(0, 1)) {
          setBrand("Visa");
          setCard(cardVisa);
        }
      });
    }

    if (number.length >= 1) {
      cards.master.map((n) => {
        if (n == number.substring(0, 1)) {
          setBrand("MasterCard");
          setCard(cardMasterCard);
        }
      });
    }

    if (number.length >= 2) {
      cards.diners.map((n) => {
        if (n == number.substring(0, 2) || n == number.substring(0, 3)) {
          setBrand("Diners");
          setCard(cardDiners);
        }
      });
    }

    if (number.length >= 2) {
      cards.amex.map((n) => {
        if (n == number.substring(0, 2)) {
          setBrand("Amex");
          setCard(cardAmex);
        }
      });
    }

    if (number.length >= 2) {
      cards.hiper.map((n) => {
        if (n == number.substring(0, 2)) {
          setBrand("Hipercard");
          setCard(cardHiperCard);
        }
      });
    }

    if (number.length >= 2) {
      cards.aura.map((n) => {
        if (n == number.substring(0, 2)) {
          setBrand("Aura");
          setCard(cardAura);
        }
      });
    }

    if (number.length >= 6) {
      cards.Elo.map((n) => {
        if (n == number.substring(0, 6)) {
          setBrand("Elo");
          setCard(cardElo);
        }
      });
    }

    setNumberCard(number);
  };

  const inCvv = () => {
    setAuxCard(card);
    setCard(cardCVV);
  };
  const outCvv = () => {
    setCard(auxCard);
  };

  const isShowCard = () => {
    methodsAvaliable.map((m) => {
      console.log(m);

      if (m == 1) {
        console.log(true);
        setShowCardBoleto(true);
      }

      if (m == 2) {
        console.log(true);
        setShowCardCredit(true);
      }
    });
  };
  return (
    <Container>
      <BackDrop open={loading} />
      <DivForm>
        <ContentMethods>
          <ContentCard
            show={showCardCredit}
            method={2}
            onClick={() => setPaymentMethod(2)}
          >
            <img
              style={{ height: 100 }}
              src="https://codigoemagrecerdevez.com.br/wp-content/uploads/2016/02/cartaodecredito.ico"
            ></img>
            <div
              style={{
                borderStyle: "solid",
                borderWidth: 2,
                textAlign: "center",
                padding: 10,
              }}
            >
              Cartão Credito
            </div>
          </ContentCard>
          <ContentCard
            show={showCardBoleto}
            method={1}
            onClick={() => setPaymentMethod(1)}
          >
            <img
              style={{ height: 100 }}
              src="https://sintepav-pe.org.br/wp-content/uploads/2016/03/boleto-icon.jpg.jpg"
            ></img>
            <div
              style={{
                borderStyle: "solid",
                borderWidth: 2,
                textAlign: "center",
                padding: 10,
              }}
            >
              Boleto Bancario
            </div>
          </ContentCard>
        </ContentMethods>
      </DivForm>
      <ContainerInformation>
        <div>
          <p>Nome: {client.name}</p>
          <p>Endereco:{client.address}</p>
          <p>Forma de pagamento: Cartão de credito</p>
          {/* <p>Nome: Fernando</p>
          <p>Endereco:Rua marechal deodoto da fonseca</p>
          <p>Forma de pagamento: Cartão de credito </p> */}
        </div>
        <div>
          {product != null ? (
            <div>
              <p>Serviço: {product.name}</p>
              <p>Valor: {valueFormat}</p>
              <p>Valor Total: {valueTotalFormat}</p>
            </div>
          ) : (
            <div>
              <p>Valor Total: R$ {valueTotalFormat}</p>
            </div>
          )}

          {/* <div>
            <p>Serviço: confort</p>
            <p>Valor: 500,00</p>
            <p>Valor Total: 500.00</p>
          </div> */}
        </div>

        <div></div>
      </ContainerInformation>
      <ContainerInformation>
        <ConteinerCardCredit>
          <DivInputsCard>
            <Form onSubmit={handdleSubmit} ref={formRef}>
              <DivInput>
                <Input
                  name="number"
                  placeholder="NUMERO CARTÃO"
                  onChange={(e) => selectCard(e.target.value)}
                  onBlur={getInstallments}
                  type="number"
                  value={numberCard}
                />
                <Input name="name" placeholder="NOME NO CARTÃO" />
                <DivMesAno>
                  <div>
                    <Select
                      native
                      value={monthValue}
                      onChange={(e) => selectMonth(e.target.value)}
                    >
                      <option value="">Mês</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      {installmentsOption.map((option) => (
                        <option value={option.quantity}>
                          {option.quantity}x - {option.installmentAmount}
                        </option>
                      ))}
                    </Select>
                  </div>

                  <div>
                    <Input name="exp_year" placeholder="ANO" />
                  </div>
                  <div>
                    <Input
                      name="security_code"
                      placeholder="CVV"
                      onFocus={inCvv}
                      onBlur={outCvv}
                    />
                  </div>
                </DivMesAno>
                <Select
                  native
                  value={installmentsValue}
                  onChange={(e) => selectInstallments(e.target.value)}
                >
                  <option value="">Escolha seu parcelamento</option>
                  {installmentsOption.map((option) => (
                    <option value={option.quantity}>
                      {option.quantity}x - {option.installmentAmount}
                    </option>
                  ))}
                </Select>
              </DivInput>
              <div></div>
            </Form>
          </DivInputsCard>
          <DivImageCard>
            <img src={card}></img>
          </DivImageCard>
        </ConteinerCardCredit>
      </ContainerInformation>
      <DivButton onClick={handdleSubmit}>Finalizar compra</DivButton>
    </Container>
  );
}

export default FormPagSeguro;
